//
// Buttons Base
//

// Button
.btn {
  --#{$prefix}btn-color: var(--kt-body-color);
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-color: transparent;

  // Reset outline
  outline: none !important;

  // Reset focus shadow
  &:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
  }

  // Remove border
  &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
      .border-active
    ):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(#{$btn-padding-y} + #{$btn-border-width})
      calc(#{$btn-padding-x} + #{$btn-border-width});

    &.btn-lg {
      padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width})
        calc(#{$btn-padding-x-lg} + #{$btn-border-width});
    }

    &.btn-sm {
      padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
        calc(#{$btn-padding-x-sm} + #{$btn-border-width});
    }

    &.btn-xs {
      padding: calc(#{$btn-padding-y-xs} + #{$btn-border-width})
        calc(#{$btn-padding-x-xs} + #{$btn-border-width});
    }
  }

  // Link
  &.btn-link {
    border: 0;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: $btn-font-weight;
  }

  // Outline
  &.btn-outline:not(.btn-outline-dashed) {
    border: 1px solid var(--kt-input-border-color);
  }

  // Outline dashed
  &.btn-outline-dashed {
    border: 1px dashed var(--kt-input-border-color);
  }

  // Flush
  &.btn-flush {
    @include button-reset();
  }

  // Flex
  &.btn-flex {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  // Align start
  &.btn-trim-start {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }

  // Align start
  &.btn-trim-end {
    justify-content: flex-end !important;
    padding-right: 0 !important;
  }
}

// Icons
.btn {
  // Font icon
  i {
    display: inline-flex;
    font-size: $font-size-base;
    padding-right: 0.35rem;
    vertical-align: middle;
    line-height: 0;
  }

  // Svg icon
  .svg-icon {
    flex-shrink: 0;
    line-height: 0;
    margin-right: 0.5rem;
    color: $primary;
  }

  // Icon only button
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: $input-height;
    width: $input-height;

    // Remove border
    &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
        .border-active
      ):not(.btn-flush) {
      border: 0;
    }

    // Sizes
    &.btn-xs {
      height: $input-height-xs;
      width: $input-height-xs;
    }

    &.btn-sx {
      height: $input-height-sx;
      width: $input-height-sx;
    }

    &.btn-sm {
      height: $input-height-sm;
      width: $input-height-sm;
    }

    &.btn-lg {
      height: $input-height-lg;
      width: $input-height-lg;
    }

    &.btn-circle {
      border-radius: 50%;
    }

    i,
    .svg-icon {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }
}

// Hover effects
.btn.btn-hover-rise {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10%);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-scale {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-rotate-end {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(4deg);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-rotate-start {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(-4deg);
    transition: transform 0.3s ease;
  }
}

.group-btn-calc {
  width: calc(100% + 10px);
  display: block;
  margin: -5px;

  .btn-calc {
    width: 48px;
    height: 48px;
    margin: 5px;
    padding: 12px 0;
    text-align: center;
    background-color: var(--kt-body-bg);
    border: none;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.64;
    color: var(--kt-black);
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover:not([disabled])/* ,
    &:focus:not([disabled])  */ {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }
}

.btn.btn-light-primary.has-condition {
  background-color: var(--kt-primary) !important;
  opacity: 0.6;
  color: var(--kt-white) !important;
  transition: opacity 350ms linear;

  & .svg-icon {
    color: var(--kt-white);
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}
.group-radio-btn {
  position: relative;
  display: inline-block;
  margin: -7px;

  .radio-item {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;

    &:hover + label,
    &:focus + label {
      border: 1px solid transparent;
      border-radius: 6px;
      color: $primary-active;
      background-color: $body-bg;
    }

    &:checked + label {
      border: 1px solid transparent;
      border-radius: 6px;
      color: $primary;
      background-color: $body-bg;
    }
  }

  .radio-btn-item {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    opacity: 0;
    visibility: hidden;

    &:hover + label,
    &:focus + label {
      border: 1px solid var(--kt-primary-active);
      border-radius: 6px;
      color: $primary-active;
    }

    &:checked + label {
      border: 1px solid var(--kt-primary);
      border-radius: 6px;
      color: $primary;
    }
  }

  .radio-label {
    min-width: 182px;
    margin: 4px;
    padding: 2.5px 4px;
    border: 1px solid transparent;
    border-radius: 6px;
    color: $bg-orang;
    cursor: pointer;
    transition: color 250ms linear;
  }

  .radio-btn-label {
    margin: 4px;
    border: 1px solid transparent;
    border-radius: 6px;
    color: $gray-400;
    cursor: pointer;
    transition: color 250ms linear, border 250ms linear;
  }

  .radio-btn {
    position: absolute;
    display: inline-block;
    width: 107px;
    height: 45px;
    margin: 4px;
    background-color: $body-bg;
    border: 1px solid transparent;
    border-radius: 6px;
    font-weight: 500;
    font-size: 8px;
    line-height: 1.33;
    text-align: center;
    color: #626976;
    cursor: pointer;

    &:hover,
    &:focus {
      border: 1px solid var(--kt-primary-active);
    }

    &.active {
      border: 1px solid var(--kt-primary);
    }
  }

  .radio-btn-item-icon {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;

    &:hover + label,
    &:focus + label {
      background-color: var(--kt-primary-active);
      color: var(--kt-white);

      & .radio-icon {
        color: var(--kt-white);
      }
    }

    &:checked + label {
      background-color: var(--kt-primary);
      color: var(--kt-white);

      & .radio-icon {
        color: var(--kt-white);
      }
    }
  }

  .radio-btn-label-icon {
    width: 67.67px;
    height: 65px;
    margin: 5px;
    padding: 10px 0px;
    border-radius: 6px;
    background-color: #e2e6f5;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 2.3;
    color: var(--kt-black);
    cursor: pointer;

    .radio-icon {
      width: 22px;
      height: 22px;
      color: var(--kt-primary);
    }
  }
}

.radio {
  position: relative;
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-right: 10px;
  cursor: pointer;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transform-origin: center;
  border: 1px solid #d8dcea;
  border-radius: 50% 50%;
  background-color: rgba(238, 240, 248, 0.5);
  transition: 120ms all ease-in-out;

  &::before {
    position: absolute;
    content: "";
    border-radius: 50%;
  }

  &::before {
    top: 0.2rem;
    left: 0.2rem;
    width: 1rem;
    height: 1rem;
    background: rgba(238, 240, 248, 0.5);
    pointer-events: all;
    transition: 120ms all ease-in-out;
  }

  &:checked {
    background-color: #d8dcea;
    transform: scale(1.2);
    transition: background-color 120ms ease-in-out;

    &::before {
      width: 1rem;
      height: 1rem;
      background: var(--kt-primary);
      border: 1px solid #d8dcea;
    }
  }
}

.radio-label {
  display: inline-flex;
  align-items: flex-start;

  &.radio-label-me {
    margin-right: 0 !important;
  }

  & span {
    width: calc(100% - 30px);
    display: inline-block;
    margin-top: 2px;
  }
}

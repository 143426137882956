//
// Form Control
//


// Form control
.form-control {
	// Dropdown shown state
	.dropdown.show > & {
		color: var(--kt-input-focus-color); 
		background-color: var(--kt-input-focus-bg);
  	    border-color: var(--kt-input-focus-border-color);
	}

	// Readonly state
	&[readonly] {
		background-color: var(--kt-input-readonly-bg);
	}

	// Solid style
	&.form-control-solid {
		background-color: var(--kt-input-solid-bg);
		border-color: var(--kt-input-solid-bg);
		color: var(--kt-input-solid-color);
		@include placeholder(var(--kt-input-solid-placeholder-color));
		transition: $transition-input;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: var(--kt-input-solid-bg-focus);
			border-color: var(--kt-input-solid-bg-focus);
			color: var(--kt-input-solid-color);
			transition: $transition-input;
		}

		&.is-invalid {
			border-color: $input-warning;
			padding-right: calc(1.5em + 1.55rem);
			background-image: none;// url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e90000%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e90000%27 stroke=%27none%27/%3e%3c/svg%3e");
			background-repeat: no-repeat;
			background-position: right calc(0.375em + 0.3875rem) center;
			background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
		}
	}

	// Transparent style
	&.form-control-transparent {
		background-color: transparent;
		border-color: transparent;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: transparent;
			border-color: transparent;
		}
	}

	// Flush
	&.form-control-flush {
		@include input-reset();
	}
}

// Placeholder colors
.placeholder-gray-500 {
	@include placeholder(var(--kt-gray-500));
}

.placeholder-white {
	@include placeholder($white);
}

// Textarea reset resize
.resize-none {
	resize: none;
}

// Form control solid bg
.form-control-solid-bg {
	background-color: var(--kt-input-solid-bg);
}

.text-error {
	display: inline-block;
	margin-bottom: 10px;
	color: $input-warning;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.25;
}
//
// Card
//
.card-wraper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  justify-content: center;
  grid-gap: 30px;
  margin: 0 auto;
  max-width: 100%;
  background-color: #EBF1F6;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    padding: 20px 8px !important;
  }
}

.card-custom {
  display: flex;
  justify-content: space-between;
  height: 100%;
  border-radius: 12px;
  padding: 30px;

  @include media-breakpoint-down(md) {
    padding: 16px;
  }
}

.card-wraper-itegration {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
  justify-content: center;
  grid-gap: 23px 40px;
  margin: 0 auto;
  max-width: 100%;
}

.card-itegration {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 266px;
  background-color: $white;
  padding: 13px 26px 13px 10px;
  border: 1px solid var(--kt-white);
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  transition: border 0.3s ease;
  cursor: pointer;

  &.active {
    border: 1px solid var(--kt-primary);

    & > .card-checked {
      display: block;
      position: absolute;
      top: -12px;
      right: -12px;
    }
  }

  & > .card-checked {
    display: none;
  }

  &.disabled {
    cursor: default;
    opacity: 0.4;

    & a {
      cursor: default;

      &:hover,
      &:focus {
        color: var(--kt-gray-900) !important;
      }
    }
  }
}

// Base
.card {
  @if ($card-border-enabled) {
    border: $card-border-width $card-border-style var(--kt-card-border-color);
  } @else {
    border: 0;
  }

  box-shadow: var(--kt-card-box-shadow);
  background-color: var(--kt-card-bg);
  margin-bottom: 2px;

  // Header
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    //min-height: $card-header-height;
    color: $card-color;
    // border-radius: $card-border-radius;

    &.card-header-proj {
      width: 100%;
      padding: $card-py $card-header-px;
      background-color: var(--kt-primary);
    }

    &.card-header-val {
      width: 100%;
      padding: 20px;
      border-bottom: none;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
      cursor: pointer;
    }

    // Title
    .card-title {
      display: flex;
      align-items: center;
      margin: $card-header-py;
      margin-left: 0;

      &.flex-column {
        align-items: flex-start;
        justify-content: center;
      }

      .card-icon {
        margin-right: 0.75rem;
        line-height: 0;

        i {
          font-size: 1.25rem;
          color: var(--kt-gray-600);
          line-height: 0;

          &:after,
          &:before {
            line-height: 0;
          }
        }

        .svg-icon {
          color: var(--kt-gray-600);
          @include svg-icon-size(24px);
        }
      }

      &,
      .card-label {
        font-weight: 500;
        font-size: 1.275rem;
        color: var(--kt-text-dark);
      }

      .card-label {
        margin: 0 0.75rem 0 0;
        flex-wrap: wrap;
      }

      // Description
      small {
        color: var(--kt-text-muted);
        font-size: 1rem;
      }

      // Headings
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }
    }

    &,
    .card-label-project {
      margin: 0;
      padding-left: 17px;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.59;
      color: $white;

      @include media-breakpoint-down(md) {
        font-size: 18px;
        padding-left: 6px;

        .btn-delete {
          margin-right: 0px !important;
        }
      }
    }

    &,
    .card-label-val {
      width: calc(100% - 32px);
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.78;
      letter-spacing: -0.01em;
      color: $black;
      text-align: start;
    }

    // Toolbar
    .card-toolbar {
      display: flex;
      align-items: center;
      margin: $card-header-py 0;
      flex-wrap: wrap;
    }
  }

  // Body
  .card-body {
    width: 100%;
    min-width: 370px;
    color: var(--kt-card-color);

    .card-box {
      padding: $card-py $card-px;
    }

    .card-btn {
      display: grid;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    .card-title {
      width: 330px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card-thumb {
    min-width: 370px;
    height: 210px;
    // margin: 0 auto;

    @include media-breakpoint-down(md) {
      min-width: 280px;
      height: 190px;
    }
  }

  .card-video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // Body values
  .card-body-val {
    width: 370px;
    color: var(--kt-card-color);
    margin-top: -20px;
    height: auto;
    overflow: hidden;
    transition: height 0.6s ease, margin-top 0.6s ease;

    .card-wraper-val {
      width: 100%;
      padding: 0 20px 10px 20px;
      margin: 0;

      .card-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 8.5px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.64;
        color: var(--kt-black);

        &:not(:last-child) {
          border-bottom: 1px solid var(--kt-body-bg);
          border-radius: 0px 0px 6px 6px;
        }
      }
    }

    &.card-body-none {
      height: 0;
      margin-top: 0;
    }
  }

  // Footer
  .card-footer {
    padding: $card-py $card-px;
    color: var(--kt-card-cap-color);
    background-color: var(--kt-card-cap-bg);
    border-top: $card-border-width $card-border-style
      var(--kt-card-border-color);
  }

  // Scroll
  .card-scroll {
    position: relative;
    overflow: auto;
  }

  // Reset padding x
  &.card-px-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.card-py-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.card-p-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
  }

  // Dashed style
  &.card-dashed {
    box-shadow: none;
    border: $card-border-width dashed var(--kt-card-border-dashed-color);

    > .card-header {
      border-bottom: 1px dashed var(--kt-card-border-dashed-color);
    }

    > .card-footer {
      border-top: 1px dashed var(--kt-card-border-dashed-color);
    }
  }

  // Bordered style
  &.card-bordered {
    box-shadow: none;
    border: $card-border-width $card-border-style var(--kt-card-border-color);
  }

  // Flush header and footer borders
  &.card-flush {
    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Enable shadow
  &.card-shadow {
    box-shadow: var(--kt-card-box-shadow);
    border: 0;
  }

  // Reset styles
  &.card-reset {
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;

    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }
}

// Project
.card-newproject {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: transparent;
  text-align: center;
  cursor: pointer;

  .card-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: $gray-800;

    @include media-breakpoint-down(md) {
      font-size: 15px;
      line-height: 19px;
    }
  }

  &.disabled-border {
    border-color: transparent;
  }

  &:active {
    border-color: transparent;
  }
}

.card-height {
  min-height: 370px;

  @include media-breakpoint-down(md) {
    min-height: 320px;
  }
}

// Quiz
.card-quiz {
  display: block;

  border: 3px dashed #e5eef4;
  border-radius: 6px;
  background-color: $white;
  text-align: center;
  cursor: pointer;

  .card-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 41px;
    color: $primary;
  }

  &:active {
    border: 3px solid transparent;
  }
}

// Responsive stretch heights
.card {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Stretch
      &.card#{$infix}-stretch {
        height: calc(100% - var(--bs-gutter-y));
      }

      // Stretch 75
      &.card#{$infix}-stretch-75 {
        height: calc(75% - var(--bs-gutter-y));
      }

      // Stretch 50
      &.card#{$infix}-stretch-50 {
        height: calc(50% - var(--bs-gutter-y));
      }

      // Stretch 33
      &.card#{$infix}-stretch-33 {
        height: calc(33.333% - var(--bs-gutter-y));
      }

      // Stretch 25
      &.card#{$infix}-stretch-25 {
        height: calc(25% - var(--bs-gutter-y));
      }

      // Header stretch
      .card-header#{$infix}-stretch {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        align-items: stretch;

        .card-toolbar {
          margin: 0;
          align-items: stretch;
        }
      }
    }
  }
}

// Utilities
.card-p {
  padding: $card-py $card-px !important;
}

.card-px {
  padding-left: $card-px !important;
  padding-right: $card-px !important;
}

.card-shadow {
  box-shadow: var(--kt-card-box-shadow);
}

.card-py {
  padding-top: $card-py !important;
  padding-bottom: $card-py !important;
}

.card-rounded {
  border-radius: $card-border-radius;
}

.card-rounded-start {
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.card-rounded-end {
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.card-rounded-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
// @include media-breakpoint-down(md) {
//   .card {
//     > .card-header:not(.flex-nowrap) {
//       padding-top: $card-header-py;
//       padding-bottom: $card-header-py;
//     }
//   }
// }

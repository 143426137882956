//
// Player
//

.app-player {
  width: 100%;
  height: calc(100% - 5px);

  &.desktop-view {
    min-width: 800px;
    //max-width: 1000px;
  }

  &.mobile-view {
    width: 335px;
  }
}

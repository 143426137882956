// The dropdown menu
.dropdown-menu {
  --#{$prefix}dropdown-color: var(--kt-dropdown-color);
  --#{$prefix}dropdown-bg: var(--kt-dropdown-bg);
  --#{$prefix}dropdown-border-color: var(--kt-dropdown-border-color);
  --#{$prefix}dropdown-border-width: var(--kt-dropdown-border-width);
  --#{$prefix}dropdown-divider-bg: var(--kt-dropdown-divider-bg);
  --#{$prefix}dropdown-box-shadow: var(--kt-dropdown-box-shadow);
  --#{$prefix}dropdown-link-color: var(--kt-dropdown-link-color);
  --#{$prefix}dropdown-link-hover-color: var(--kt-dropdown-link-hover-color);
  --#{$prefix}dropdown-link-hover-bg: var(--kt-dropdown-link-hover-bg);
  --#{$prefix}dropdown-link-active-color: var(--kt-dropdown-link-active-color);
  --#{$prefix}dropdown-link-active-bg: var(--kt-dropdown-link-active-bg);
  --#{$prefix}dropdown-link-disabled-color: var(
    --kt-dropdown-link-disabled-color
  );
  --#{$prefix}dropdown-header-color: var(--kt-dropdown-header-color);
  --#{$prefix}dropdown-header-bg: var(--kt-dropdown-header-bg);
  --#{$prefix}dropdown-min-width: 74px !important;

  max-height: 200px;
  overflow: scroll;
}

.dropdown-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 74px;
  height: 38px;
  padding: 9px 15px;
  border: none;
  border-radius: 6px;

  &.dropdown-button-form {
    width: 100%;
    height: 47px;
    padding: 15px 15px;
    background-color: var(--kt-white);

    & .dropdown-button-text {
      margin-bottom: 0px;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: start;
      color: $gray-900;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.214;
    }
  }

  &.dropdown-button-search {
    min-width: 223px;
    width: 20%;
    height: 41px;
  }

  & .dropdown-button-text {
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    color: $gray-600;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.67;
  }
}

.dropdown-button-chips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 74px;
  min-height: 38px;
  padding: 15px;
  border: none;
  border-radius: 6px;
}

.chips-wraper {
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  margin: -5px;
}

.chip {
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
  max-width: 192px;
  padding: 4px 10px;
  margin: 5px;
  background-color: $white;
  border-radius: 6px;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.67;
  color: $gray-600;

  .chip-text {
    max-width: 159px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chip-btn {
    background-color: transparent;
    border: none;
    margin: 0;
    margin-left: 10px;
    padding: 0;
  }
}

.dropdown-button-fonts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 160px;
  padding: 0 10px;
  border: none;
  background-color: transparent;

  .button-fonts-text {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dropend {
  .dropdown-menu {
    position: absolute;
    top: 0;
    left: 50px;
    margin: 0px;
    padding: 0;
    background-color: var(--#{$prefix}dropdown-bg);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}

.dropbottom {
  .dropdown-menu {
    position: absolute;
    // top: 43px;
    right: 0;
    min-width: auto;
    margin: 0px;
    padding: 10px 15px;
    background-color: $gray-100;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  .dropdown-item {
    margin-bottom: 5px;
    background-color: transparent;
    color: var(--kt-black);
    font-weight: 500;
    font-size: 12px;
    line-height: 1.67;

    .dropdown-icon {
      fill: currentColor;
    }

    &:hover,
    &:focus,
    &:active,
    .active {
      background-color: var(--#{$prefix}dropdown-link-hover-bg);
      color: var(--#{$prefix}dropdown-link-hover-color);

      .dropdown-icon {
        color: var(--#{$prefix}dropdown-link-hover-color);
      }
    }
  }
}

.dropdown-header {
  position: relative;
  padding: 8px 18px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--#{$prefix}dropdown-header-bg);
  color: var(--#{$prefix}dropdown-header-color);
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;

  &::before {
    content: " ";
    position: absolute;
    left: -17px;
    border: 11px solid transparent;
    border-right: 11px solid var(--#{$prefix}dropdown-header-bg);
  }
}

.dropdown-item {
  padding: 5px 20px;
  border-bottom: 1px solid var(--#{$prefix}dropdown-link-hover-bg);
  background-color: transparent;
  color: var(--#{$prefix}dropdown-color);
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;

  .dropdown-icon {
    fill: currentColor;
  }

  &:hover,
  &:focus,
  &:active,
  .active {
    background-color: var(--#{$prefix}dropdown-link-hover-bg);
    color: var(--#{$prefix}dropdown-link-hover-color);

    .dropdown-icon {
      color: var(--#{$prefix}dropdown-link-hover-color);
    }
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @include media-breakpoint-down(md) {
    white-space: normal;
  }
}

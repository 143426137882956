//
// SubMenu
//

// General mode
.app-submenu {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-bottom: 30px;

  .submenu-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: -5px;
  }
}

.submenu-quiz {
  width: calc(100% - 293px);
}

.submenu-btn {
  margin: 5px;
}

// Base
.app-submenu {
  padding-left: $app-submenu-padding-x !important;
  padding-right: $app-submenu-padding-x !important;
  padding-top: $app-submenu-padding-y !important;
  padding-bottom: $app-submenu-padding-y !important;
  background-color: var(--kt-app-submenu-base-bg-color);
  box-shadow: var(--kt-app-submenu-base-box-shadow);
  border: var(--kt-app-submenu-base-border);
  border-radius: var(--kt-app-submenu-base-border-radius);
}

// States
.app-submenu {
  height: var(--kt-app-submenu-height);
}

//
// Toolbar
//

// General mode
.app-toolbar {
  // display: flex;
  // align-items: stretch;
  position: absolute;
  top: $app-main-padding-y;
  right: $app-main-padding-x;
  margin-left: $app-main-padding-x;
  width: var(--kt-app-toolbar-base-width);
  max-height: calc(
    100vh - $app-main-padding-x - $app-main-padding-x -
      var(--kt-app-footer-height) - var(--kt-app-header-height)
  );
  overflow: auto;
}

.app-toolbar.leads {
  top: inherit;

  @include media-breakpoint-down(md) {
    position: static;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

.toolbar-card {
  margin-bottom: 1px;

  .toolbar-title {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.64;
    color: $black;
  }
  & .border-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #eef0f8;
  }
  & .border-card {
    padding-top: 10px;
    border-top: 1px solid #eef0f8;
  }
}

.toolbar-text-settings {
  padding-bottom: 10px;

  .toolbar-btn:not(:nth-last-child(1)) {
    margin-right: 5px;
  }

  .svg-icon.btn-text-icon-bold svg {
    width: 7px;
    height: 9px;
  }

  .svg-icon.btn-text-icon-italics svg {
    width: 8px;
    height: 9px;
  }

  .svg-icon.btn-text-icon-underlined svg {
    width: 9px;
    height: 12px;
  }
}

.toolbar-answer {
  padding: 10px 15px 0 15px;
  border: 1px solid #eef0f8;

  .svg-icon.btn-text-icon svg {
    width: 17px;
    height: 17px;
  }
}

.toolbar-input {
  padding: 15px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.67;
  resize: none;
}

.toolbar-btn {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.64;
}

.toolbar-button-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
  justify-content: center;
  grid-gap: 10px;
  width: 100%;
}

.toolbar-timeout {
  // margin-bottom: 20px;
  padding: 10px 20px 20px 20px;
  background-color: #eef0f8;
  border-radius: 6px;

  & .timeout-text {
    color: var(--kt-black);
    font-weight: 400;
    font-size: 12px;
    line-height: 1.92;
  }

  & .timeout-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

// Desktop mode
// Base
.app-toolbar {
  width: var(--kt-app-toolbar-base-width);
  padding-top: $app-toolbar-padding-y;
  padding-bottom: $app-toolbar-padding-y;
  padding-left: $app-toolbar-padding-x;
  padding-right: $app-toolbar-padding-x;
  background-color: var(--kt-app-toolbar-base-bg-color);
  box-shadow: var(--kt-app-toolbar-base-box-shadow);
  border: var(--kt-app-toolbar-base-border);
  border-radius: var(--kt-app-toolbar-base-border-radius);
}

.toolbar-card {
  padding-top: $app-toolbar-card-padding-y;
  padding-bottom: $app-toolbar-card-padding-x;
  padding-left: $app-toolbar-card-padding-x;
  padding-right: $app-toolbar-card-padding-x;
  background-color: var(--kt-app-toolbar-card-base-bg-color);
}

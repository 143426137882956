.picker {
  position: relative;
}

.swatch {
  width: 23px;
  height: 23px;
  border: 1px solid #eef0f8;
  border-radius: 6px;
  cursor: pointer;
}

.popover {
  padding: 20px;
  border: none;
  border-radius: 6px;
  background-color: $gray-900;

  input {
    display: inline-block;
    box-sizing: border-box;
    width: 85px;
    margin-left: 8px;
    padding: 5px 15px;
    border: 1px solid transparent;
    border-radius: 6px;
    background: rgba(238, 240, 248, 0.1);
    outline: none;
    font: inherit;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $white;
    text-transform: uppercase;
    text-align: center;
  }

  input:focus {
    border-color: $primary;
  }
}

.box-swatches {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}

.picker__swatches {
  height: 26px;
  padding: 0;
  margin: 0;
}

.picker__swatch {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 6px;
  margin: 0 6px 0 0;
  border: none;
}

.popover .react-colorful {
  width: 234px;
  height: 144px;
  border-radius: 6px;
}

.popover .react-colorful__saturation {
  border-radius: 6px;
}

.popover .react-colorful__last-control {
  width: 100%;
  height: 15px;
  margin-top: 15px;
  border-radius: 5px;
  border: none;
}

.popover .react-colorful__pointer {
  width: 20px;
  height: 20px;
}

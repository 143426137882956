//
// List group
//

.list-group {
  --#{$prefix}list-group-color: var(--kt-list-group-color);
  --#{$prefix}list-group-bg: var(--kt-list-group-bg);
  --#{$prefix}list-group-border-color: var(--kt-list-group-border-color);
  --#{$prefix}list-group-action-color: var(--kt-list-group-action-color);
  --#{$prefix}list-group-action-hover-color: var(
    --kt-list-group-action-hover-color
  );
  --#{$prefix}list-group-action-hover-bg: var(--kt-list-group-hover-bg);
  --#{$prefix}list-group-action-active-color: var(
    --kt-list-group-action-active-color
  );
  --#{$prefix}list-group-action-active-bg: var(
    --kt-list-group-action-active-bg
  );
  --#{$prefix}list-group-disabled-color: var(--kt-list-group-disabled-color);
  --#{$prefix}list-group-disabled-bg: var(--kt-list-group-disabled-bg);
  --#{$prefix}list-group-active-color: var(--kt-list-group-active-color);
  --#{$prefix}list-group-active-bg: var(--kt-list-group-active-bg);
  --#{$prefix}list-group-active-border-color: var(
    --kt-list-group-active-border-color
  );
}

.list-group {
  list-style: none;

  .list-group-item {
    display: flex;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 6px;
    background-color: var(--kt-list-group-bg);
  }

  .list-group-btn {
    display: flex;
    align-items: center;
    // height: 57px;
  }

  .list-group-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 62px;
    padding: 15px;
    border-radius: 6px;
    background-color: $gray-100;
  }

  .list-group-link-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    padding: 7px 15px;
    border-radius: 6px;
    background-color: $gray-100;
  }

  .list-group-title {
    width: 100%;
    margin: 0;
    text-align: start;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.62;
    color: $black;
  }

  .list-group-text {
    min-height: 87px;
    padding: 15px;
    border-radius: 6px;
    background-color: $gray-100;
    color: $black;
    text-align: start;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.64;
  }
}

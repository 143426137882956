.login-thumba_box {
    @media (max-width: 991px) {
        display: none;
    }

    @media (min-width: 992px) {
        position: relative;
        display: flex;
        justify-content: center;
        width: 50%;
        height: 100%;
        max-height: 100%;
        background-color: #24003D;
    
        .login-thumba {
            position: absolute;
            max-height: 100%;
    
            &:first-child {
                width: 72%;
                aspect-ratio: 550/794;
                top: 0;
     
            }
    
            &:last-child {
                width: 100%;
                aspect-ratio: 739/624;
                bottom: 0;
                right: -9%;
    
            }
    
            & svg {
                width: 100%;
                height: 100%;
            }
        }
    }

}

.login-content {
    display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        overflow: auto;


        & > div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            max-height: 100%;
            overflow: auto;
            padding: 30px 30px 40px 30px;
        }

    @media (max-width: 991px) {
        width: 100%;
    } 

    @media (min-width: 992px) {
        width: 50%;
        padding-left: 67px;

        & > div {
            align-items: start;
        }
    }
}

.login-form_box {
    width: 100%;
    max-width: 440px;
    padding: 20px 30px 30px 30px;
    border-radius: 12px;
    background-color: var(--kt-white);
}

.login-form_title {
    margin-bottom: 15px;
    color: var(--kt-gray-900);
    font-weight: 700;
    font-size: 22px;
    line-height: 1.59;
    letter-spacing: -0.22px;
}

.login-form_title-second {
    margin-bottom: 15px;
    color: var(--kt-gray-900);
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.14px;
  }
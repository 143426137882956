//
// Light Sidebar(used by Light Sidebar layout only)
//

.app-sidebar {
  background-color: var(--kt-app-sidebar-light-bg-color);
  border-right: 0 !important;

  .hover-scroll-overlay-y {
    @include scrollbar-color(
      var(--kt-app-sidebar-light-scrollbar-color),
      var(--kt-app-sidebar-light-scrollbar-color-hover)
    );
  }

  // .app-sidebar-logo {
  //   border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);
  // }

  .menu {
    font-weight: $font-weight-bold;
    font-size: 15px;
    line-height: 24px;

    .menu-item {
      .menu-heading {
        color: var(--kt-app-sidebar-light-menu-heading-color) !important;
      }

      &.back-item {
        font-weight: $font-weight-semibold;
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 0;
        @include menu-link-default-state(
          $title-color: var(--kt-primary),
          $icon-color: null,
          $bullet-color: null,
          $arrow-color: null,
          $bg-color: null
        );
        // .menu-link {
        //   padding: 15px 20px;
        // }
      }

      @include menu-link-default-state(
        $title-color: var(--kt-gray-900),
        $icon-color: var(--kt-gray-500),
        $bullet-color: var(--kt-gray-500),
        $arrow-color: var(--kt-gray-500),
        $bg-color: null
      );

      @include menu-link-hover-state(
        $title-color: var(--kt-gray-900),
        $icon-color: var(--kt-gray-700),
        $bullet-color: var(--kt-gray-700),
        $arrow-color: var(--kt-gray-700),
        $bg-color: var(--kt-app-sidebar-light-menu-link-bg-color-active)
      );

      @include menu-link-show-state(
        $title-color: var(--kt-gray-900),
        $icon-color: var(--kt-gray-700),
        $bullet-color: var(--kt-gray-700),
        $arrow-color: var(--kt-gray-700),
        $bg-color: null
      );

      @include menu-link-here-state(
        $title-color: var(--kt-gray-900),
        $icon-color: var(--kt-gray-700),
        $bullet-color: var(--kt-gray-700),
        $arrow-color: var(--kt-gray-700),
        $bg-color: null
      );

      @include menu-link-active-state(
        $title-color: var(--kt-gray-900),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-app-sidebar-light-menu-link-bg-color-active)
      );

      .back-arrow {
        display: flex;
        align-items: stretch;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        margin-right: 13px;
        width: $menu-arrow-size;
        height: $menu-arrow-size;

        &::before {
          display: block;
          width: 100%;
          content: " ";
          will-change: transform;
          background-size: 100% 100%;
          @include svg-bg-icon(arrow-start, var(--kt-primary));
        }
      }
    }
  }
}

[data-kt-app-header-fixed="true"] {
  .app-sidebar {
    .app-sidebar-logo {
      border-bottom: 1px dashed var(--kt-app-sidebar-light-separator-color);
    }
  }
}

.instructions-wrapper {
    position: fixed;
    left: 6px;
    bottom: 6px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-end;

    .img-wrapper {
        position: relative;
        width: 100%;
        max-width: 220px;
        border: 1px solid $primary;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;

        img {
            max-width: 100%;
        }

        .svg-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 2;
            width: 32px;
            height: 32px;
            transform: translate(-50%, -50%);

            path {
                stroke: $white;
            }
        }
    }
}

.instructions {
    .video-block {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
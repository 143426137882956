//
// Stepper
//

// Base
.stepper {
  // Content
  [data-kt-stepper-element="info"],
  [data-kt-stepper-element="content"] {
    display: none;

    &.current {
      display: flex;
    }
  }

  // Enable cursor pointer on clickable steppers
  .stepper-item[data-kt-stepper-action="step"] {
    cursor: pointer;
  }

  // States
  & {
    [data-kt-stepper-action="final"] {
      display: none;
    }

    [data-kt-stepper-action="previous"] {
      display: none;
    }

    [data-kt-stepper-action="next"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"] {
      display: none;
    }
  }

  &.first {
    [data-kt-stepper-action="previous"] {
      display: none;
    }

    [data-kt-stepper-action="next"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"] {
      display: none;
    }
  }

  &.between {
    [data-kt-stepper-action="previous"] {
      display: inline-block;
    }

    [data-kt-stepper-action="next"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"] {
      display: none;
    }
  }

  &.last {
    [data-kt-stepper-action="final"] {
      display: inline-block;
    }

    [data-kt-stepper-action="final"].btn-flex {
      display: flex;
    }

    [data-kt-stepper-action="previous"] {
      display: inline-block;
    }

    [data-kt-stepper-action="previous"].btn-flex {
      display: flex;
    }

    [data-kt-stepper-action="next"] {
      display: none;
    }

    [data-kt-stepper-action="next"].btn-flex {
      display: flex;
    }

    [data-kt-stepper-action="submit"] {
      display: inline-block;
    }

    [data-kt-stepper-action="submit"].btn-flex {
      display: flex;
    }
  }

  .stepper-items {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 13px 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .stepper-number {
    padding: 0;
    margin: 0;
    text-align: center;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: $black;
  }

  &.stepper-font {
    .stepper-number {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
    }
  }

  &.stepper-card-width {
    width: 97px;
    height: 23px;

    .stepper-number {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.64;
    }
  }
}

.from-to {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .from-to-label {
    display: inline-flex;
    margin-right: 20px;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.6;
    color: var(--kt-black);
  }

  .from-to-input {
    display: inline-flex;
    width: 63px;
    padding: 7px 0;
    background: var(--kt-body-bg);
    border: 1px solid var(--kt-body-bg);
    border-radius: 6px;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.69;
  }
}

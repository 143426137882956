//
// Labels
//

.form-label {
	width: 100%;
	margin-bottom: 0px;
	color: var(--kt-form-label-color);
	font-size: 14px;

	& span {
		display: block;
		margin-bottom: 10px;
	}

	&.label-social {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		padding: 15px;
		background-color: $gray-100;
		border-radius: 6px;

		& span {
			display: block;
			height: 25px;
			margin-bottom: 0px;
		}
	}
}

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
	color: var(--kt-form-label-color);
}

//
// App Topbar
//

.app-topbar {
  display: flex;
  //   align-items: stretch;

  .app-topbar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 87px;
    height: 100%;

    .app-topbar-button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      font-size: 12px !important; //$font-size-base
      font-weight: $font-weight-semibold !important;
      line-height: 20px !important;
      color: var(--kt-primary) !important;
      border: none;
      background-color: transparent;

      .topbar-icon {
        display: block;
        width: 24px;
        height: 24px;
        text-align: center;

        svg {
          fill: var(--kt-primary) !important;
        }
      }
    }

    &:hover {
      background-color: var(--kt-primary);
      .app-topbar-button {
        color: var(--kt-white) !important;

        .topbar-icon svg {
          fill: var(--kt-white) !important;
        }
      }
    }
  }
}

//
// Wrapper
//

// General mode
.app-wrapper {
  display: flex;
}

// Desktop mode
  // Integration
  .app-wrapper {
    transition: $app-wrapper-transition;



    // Sidebar

    margin-left: calc(
      var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) +
        var(--kt-app-sidebar-gap-end, 0px)
    );

    @include media-breakpoint-down(md) {
      margin-left: 65px;
    }

    // Sidebar Panel
    [data-kt-app-sidebar-panel-fixed="true"] & {
      margin-left: calc(
        var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) +
          var(--kt-app-sidebar-gap-end, 0px) + var(--kt-app-sidebar-panel-width) +
          var(--kt-app-sidebar-panel-gap-start, 0px) +
          var(--kt-app-sidebar-panel-gap-end, 0px)
      );
    }

    // Aside
    [data-kt-app-aside-fixed="true"] & {
      margin-right: calc(
        var(--kt-app-aside-width) + var(--kt-app-aside-gap-start, 0px) +
          var(--kt-app-aside-gap-end, 0px)
      );
    }

    // Footer
    [data-kt-app-footer-fixed="true"] & {
      margin-bottom: var(--kt-app-footer-height);
    }
  }




//
// Input group
//
.form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(570px, 1fr));
  justify-content: center;
  grid-gap: 30px;
  margin: 0 auto;
  max-width: 100%;
}

.login-form {
  width: 100%;

  & .form-label {
    margin-bottom: 10px;
    text-align: start;
    color: #7C7C7C;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.21;

    &.error {
      color: $input-warning;
    }
  }

  & .form-control {
    width: 100%;
    margin-bottom: 25px;
    padding: 15px;
    border: 1px solid var(--kt-gray-100);
    border-radius: 6px;
    background-color: var(--kt-gray-100);
    color: var(--kt-gray-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;

    &.error {
      color: $input-warning;
    }
  }

  & .text-errors {
    margin-top: -15px;
    color: $input-warning;    
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
  }
}

.form-portfolio {
  width: 900px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
}

.form-item {
  padding: 20px 30px 30px 30px;
  background-color: var(--kt-white);
  border-radius: 12px;
}

.form-item-more {
  width: 100%;
  background-color: transparent;
}

.form-group {
  display: grid;
  grid-gap: 15px 20px;
  grid-template-columns: repeat(2, 1fr);
}

.form-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.form-button {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px;
  background-color: transparent;
  color: var(--kt-primary);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.64;

  &.btn.btn-light-primary {
    background-color: transparent;
  }

  & span {
    color: inherit;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.64;
    white-space: nowrap;
    word-wrap: break-word;

    & svg {
      fill: inherit;
      color: inherit;
    }
  }

  & span:last-child {
    margin-left: 20px;
  }
}

.form-button.form-button_submit {
  margin-right: 32px;
  padding: 9px 16px;
  background-color: var(--kt-danger);
  color: var(--kt-white);
  border-radius: 6px;
  white-space: nowrap;
  transition: color 0.3s ease, background-color 0.3s ease;

  & span {
    color: var(--kt-white);
  }

  &:hover,
  &:focus {
    color: var(--kt-white);
    background-color: var(--kt-primary-active);
  }
}

.input-group-text {
  color: var(--kt-input-group-addon-color);
  background-color: var(--kt-input-group-addon-bg);
  border: $input-border-width solid var(--kt-input-group-addon-border-color);
}

.input-file-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 105px;
  border: 1px solid $gray-100;
  border-radius: 6px;
  background-color: $white;

  .label-file {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.67;
    text-align: center;
    color: $gray-600;
    cursor: pointer;
  }

  .label-file-text {
    display: block;
    width: 81px;
  }
}

.thumba-image-file {
  position: relative;
  width: 100%;
  height: 105px;
  border: 1px solid $gray-100;
  border-radius: 6px;
  overflow: hidden;

  .image-file {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .btn-file {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    border: none;
    background: var(--kt-white);
    border-radius: 50%;
    font-size: 1rem;
    line-height: 1;
  }
}

.form-item-file {
  margin-top: 10px;
  padding: 9px 30px 9px 30px;
  background-color: var(--kt-white);
  border-radius: 12px;
}

.form-thumba,
.form-thumba-avatar {
  width: 53px;
  height: 67px;
  margin-right: 20px;
  background-color: transparent;

  & img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.form-thumba-avatar {
  height: 53px;
  margin-block: 7px;
  border-radius: 50%;
}

.form-title {
  width: 100%;
  margin-bottom: 0px;
  color: var(--kt-gray-900);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.625;
}

.form-description {
  margin-right: 20px;
  white-space: nowrap;
  color: var(--kt-gray-800);
  font-weight: 600;
  font-size: 14px;
  line-height: 1.62;
}

.referral_input {
  width: 246px;
  margin-right: 15px;
  color: var(--kt-gray-900);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.64;

  &.referral_input-border {
    border: 1px solid rgba(0, 0, 0, 0.10);
    background-color: #ECF0F2;
  }

  &.referral_input-text {
    padding: 11.5px 15px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background-color: #ECF0F2;
    color: var(--kt-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
  }
}
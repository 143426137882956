.help-btn-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  padding: 0;
  border-radius: 50%;
  color: var(--kt-text-white);
  background-color: var(--kt-gray-700);
  font-weight: 500;
  font-size: 10px;
  line-height: 1.64;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: var(--kt-primary);
  }
}

.help-container {
  width: 226px;
  background: var(--kt-white);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-weight: 500;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: -23px;
  //   top: 8px;
  //   border: 13px solid transparent;
  //   border-left: 19px solid var(--kt-primary);
  // }

  .help-title {
    width: 100%;
    padding: 8px 18px;
    border-radius: 10px 10px 0 0;
    background-color: var(--kt-primary);
    color: var(--kt-white);
    font-size: 14px;
    line-height: 1.64;
  }

  .help-text {
    text-align: left;
    padding: 5px 20px;
    color: var(--kt-black);
    font-size: 12px;
    line-height: 1.67;
  }

  .help-radius {
    border-radius: 10px;
  }
}

.emoji-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
  padding: 0;
  border-radius: 50%;
  color: transparent;
  background-color: transparent;
  line-height: 1;
  cursor: pointer;

  .emoji-btn-delete {
    position: absolute;
    top: -1px;
    right: -3px;
    width: 10px;
    height: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: none;
    border-radius: 50%;
    color: var(--kt-white);
    background-color: #d9d9d9;
    transition: background-color 250ms linear;

    &:hover,
    &:focus {
      background-color: var(--kt-primary);
    }
  }
}

.emojisContainer {
  .EmojiPickerReact {
    background-color: #d9d9d9;
  }

  aside.EmojiPickerReact.epr-main {
    border: none;
    border-radius: 10px;
  }
}

//
// Root
//

// Light mode
@include theme-light() {
  // Reboot
  --kt-app-bg-color: #{$app-bg-color};
  --kt-app-blank-bg-color: #{$app-blank-bg-color};

  // Header base
  --kt-app-header-base-bg-color: #{$app-header-base-bg-color};
  --kt-app-header-base-bg-color-mobile: #{$app-header-base-bg-color-mobile};
  --kt-app-header-base-box-shadow: #{$app-header-base-box-shadow};
  --kt-app-header-base-box-shadow-mobile: #{$app-header-base-box-shadow-mobile};
  --kt-app-header-base-menu-link-bg-color-active: #{$app-header-base-menu-link-bg-color-active};

  // Header light
  --kt-app-header-light-separator-color: #{$app-header-light-separator-color};

  // Sidebar base
  --kt-app-sidebar-base-toggle-btn-box-shadow: #{$app-sidebar-base-toggle-btn-box-shadow};
  --kt-app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color};

  // Sidebar light
  --kt-app-sidebar-light-bg-color: #{$app-sidebar-light-bg-color};
  --kt-app-sidebar-light-box-shadow: #{$app-sidebar-light-box-shadow};
  --kt-app-sidebar-light-separator-color: #{$app-sidebar-light-separator-color};
  --kt-app-sidebar-light-scrollbar-color: #{$app-sidebar-light-scrollbar-color};
  --kt-app-sidebar-light-scrollbar-color-hover: #{$app-sidebar-light-scrollbar-color-hover};
  --kt-app-sidebar-light-menu-heading-color: #{$app-sidebar-light-menu-heading-color};
  --kt-app-sidebar-light-menu-link-bg-color-active: #{$app-sidebar-light-menu-link-bg-color-active};
  --kt-app-sidebar-light-header-menu-link-bg-color-active: #{$app-sidebar-light-header-menu-link-bg-color-active};
  --kt-app-sidebar-light-logo-bg-color: #{$app-sidebar-light-logo-bg-color};

  // Toolbar base
  --kt-app-toolbar-base-width: #{$app-toolbar-base-width};
  --kt-app-toolbar-base-bg-color: #{$app-toolbar-base-bg-color};
  --kt-app-toolbar-base-bg-color-mobile: #{$app-toolbar-base-bg-color-mobile};
  --kt-app-toolbar-base-box-shadow: #{$app-toolbar-base-box-shadow};
  --kt-app-toolbar-base-box-shadow-mobile: #{$app-toolbar-base-box-shadow-mobile};
  --kt-app-toolbar-base-border: #{$app-toolbar-base-border};
  --kt-app-toolbar-base-border-mobile: #{$app-toolbar-base-border-mobile};
  --kt-app-toolbar-base-border-radius: #{$app-toolbar-base-border-radius};
  --kt-app-toolbar-base-border-radius-mobile: #{$app-toolbar-base-border-radius-mobile};

  // Toolbar card
  --kt-app-toolbar-card-base-bg-color: #{$app-toolbar-card-base-bg-color};
  --kt-app-toolbar-card-base-bg-color-mobile: #{$app-toolbar-card-base-bg-color-mobile};

  // Submenu base
  --kt-app-submenu-base-bg-color: #{$app-submenu-base-bg-color};
  --kt-app-submenu-base-bg-color-mobile: #{$app-submenu-base-bg-color-mobile};
  --kt-app-submenu-base-box-shadow: #{$app-submenu-base-box-shadow};
  --kt-app-submenu-base-box-shadow-mobile: #{$app-submenu-base-box-shadow-mobile};
  --kt-app-submenu-base-border: #{$app-submenu-base-border};
  --kt-app-submenu-base-border-mobile: #{$app-submenu-base-border-mobile};
  --kt-app-submenu-base-border-radius: #{$app-submenu-base-border-radius};
  --kt-app-submenu-base-border-radius-mobile: #{$app-submenu-base-border-radius-mobile};

  // Footer
  --kt-app-footer-bg-color: #{$app-footer-bg-color};
  --kt-app-footer-bg-color-mobile: #{$app-footer-bg-color-mobile};
}

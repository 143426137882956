//
// Form controls
//

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.form-control {
  color: var(--kt-input-color);
  background-color: var(--kt-input-bg);
  border: $input-border-width solid var(--kt-input-border-color);
  box-shadow: none !important;
  //@include box-shadow(var(--kt-input-box-shadow));

  &:focus {
    color: var(--kt-input-focus-color);
    background-color: var(--kt-input-focus-bg);
    border-color: var(--kt-input-focus-border-color);
    //box-shadow: var(--kt-input-focus-box-shadow);
  }

  // Placeholder
  &::placeholder {
    color: var(--kt-input-placeholder-color);
  }

  // Disabled and read-only inputs
  &:disabled,
  &[readonly] {
    color: var(--kt-input-disabled-color);
    background-color: var(--kt-input-disabled-bg);
    border-color: var(--kt-input-disabled-border-color);
  }

  // File input buttons theming
  &::file-selector-button {
    color: var(--kt-form-file-button-color);
    @include gradient-bg(var(--kt-form-file-button-bg));
  }

  &:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--kt-form-file-button-hover-bg);
  }
}

// Readonly controls as plain text
.form-control-plaintext {
  color: var(--kt-input-plaintext-color);
}

.form-control.form-control-profile {
  width: 100%;
  margin-bottom: 0px;
  padding: 15px;
  background-color: $gray-100;
  border: 1px solid $gray-100;
  border-radius: 6px;
  color: $gray-900;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;

  &.textarea {
    height: 86px;
    resize: none;
  }
}

.form-control.form-control-social {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 0px;
  padding: 9px 15px;
  background-color: var(--kt-white);
  border: 1px solid var(--kt-white);
  border-radius: 6px;
  color: $gray-900;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
}

.form-search {
  position: relative;
  width: 100%;

  & input {
    height: 41px;

    &::placeholder {
      color: $gray-600;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.67;
    }
  }

  & span {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }

}
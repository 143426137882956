//
// Footer
//

// General mode
.app-footer {
  transition: $app-footer-transition;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-align: center;
  align-items: stretch;
  min-width: 1160px;
  width: 100%;

  .footer-link {
    padding: 11px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.67;
    color: var(--kt-primary);
    opacity: 0.6;

    border: none;
    background-color: inherit;

    &:hover,
    &:focus,
    &.active {
      color: var(--kt-primary-active);
      opacity: 1;
    }

    &:active {
      color: var(--kt-primary);
      opacity: 1;
    }
  }
}

// Desktop mode
// Base
.app-footer {
  @include property(z-index, $app-footer-z-index);

  background-color: var(--kt-app-footer-bg-color);
  box-shadow: var(--kt-app-footer-box-shadow);
  border-top: var(--kt-app-footer-border-top);
}

// Vars
:root {
  --kt-app-footer-height: #{$app-footer-height};
  --kt-app-footer-padding-y: #{$app-footer-padding-y};
}

// States
.app-footer {
  height: var(--kt-app-footer-height);
  padding-left: var(--kt-app-footer-padding-y);
  padding-right: var(--kt-app-footer-padding-y);

  [data-kt-app-footer-fixed="true"] & {
    @include property(z-index, $app-footer-fixed-z-index);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Integration
.app-footer {
  // Sidebar
  [data-kt-app-sidebar-push-footer="true"] & {
    left: calc(
      var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) +
        var(--kt-app-sidebar-gap-end, 0px)
    );
  }

  // Sidebar Panel
  [data-kt-app-sidebar-panel-fixed="true"][data-kt-app-sidebar-panel-push-footer="true"]
    & {
    left: calc(
      var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) +
        var(--kt-app-sidebar-gap-end, 0px) + var(--kt-app-sidebar-panel-width) +
        var(--kt-app-sidebar-panel-gap-start, 0px) +
        var(--kt-app-sidebar-panel-gap-end, 0px)
    );
  }

  // Aside
  [data-kt-app-aside-fixed="true"][data-kt-app-aside-push-footer="true"] & {
    right: calc(
      var(--kt-app-aside-width) + var(--kt-app-aside-gap-start, 0px) +
        var(--kt-app-aside-gap-end, 0px)
    );
  }
}

//
// Sidebar
//

// Desktop mode
.app-sidebar-toggle {
  box-shadow: var(--kt-app-sidebar-base-toggle-btn-box-shadow) !important;
  background-color: var(--kt-app-sidebar-base-toggle-btn-bg-color) !important;

  &.btn-icon .svg-icon svg {
    color: $white;
  }

  .active .svg-icon {
    transform: rotateZ(0deg) !important;
  }
}

[dir="rtl"] {
  .app-sidebar-toggle {
    .svg-icon {
      transform: rotateZ(180deg);
    }

    &.active .svg-icon {
      transform: rotateZ(0deg) !important;
    }
  }
}

.app-sidebar-logo {
  height: var(--kt-app-header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-shrink: 0;
  background-color: var(--kt-app-sidebar-light-logo-bg-color);
}

.app-sidebar-menu {
  width: 265px;
  transition: padding .2s;

  .menu-back {
    width: calc(100% + 1.5rem);
    margin-top: -10px;
    margin-left: -10px;
    padding: 15px 10px;
    background-color: #e2d0bb;
    transition: background-color 0.5s ease;

    &.menu
      .menu-item:not(.here)
      .menu-link:hover:not(.disabled):not(.active):not(.here) {
      background-color: transparent !important;
    }

    &:hover {
      background-color: var(--kt-app-sidebar-light-menu-link-bg-color-active);
    }
  }
  // .menu {
  //   & > .menu-item {
  //     margin-left: 0.115rem;
  //   }
  // }
}

//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;
$blac: #000;

// Theme colors
// Primary
$primary: #8405da;
$primary-active: #520189;
$primary-light: #eef6ff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
$success: #47be7d;
$success-active: #1bc5bd;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #3e97ff;
$info-active: #8405da;
$info-light: #eef6ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #ff8816;
$danger-active: #d9214e;
$danger-light: #ffe0b2;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #f64e60; //#ffc700;
$warning-active: #ee2d41; //#f1bc00;
$warning-light: #FBE7E6; //#fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

// Custom
$sidebar-bg: #f1ddc5;
$input-warning: #e90000;
$disabled-bg: #5e6278;
$primary-focus: #520189;
$info-btn: #3e97ff;
$check-bg: #c9f7f5;
$check-color: #6b6b6b;
$label-check-color: #686868;
$bg-orang: #ff8816;

//
// sidebar
//

// General mode
.app-sidebar {
  transition: $app-sidebar-base-transition;
}

// Utilities
.app-sidebar-minimize-d-flex,
.app-sidebar-sticky-d-flex,
.app-sidebar-collapse-d-flex,
.app-sidebar-minimize-mobile-d-flex,
.app-sidebar-collapse-mobile-d-flex {
  display: none;
}

// Desktop mode
// Base
.app-sidebar {
  display: flex;
  flex-shrink: 0;
  width: var(--kt-app-sidebar-width);
  overflow: hidden;

  background-color: var(--kt-app-sidebar-base-bg-color);
  box-shadow: var(--kt-app-sidebar-base-box-shadow);
  border-left: var(--kt-app-sidebar-base-border-start);
  border-right: var(--kt-app-sidebar-base-border-end);

  @include property(z-index, $app-sidebar-base-z-index);
  @include property(margin-left, $app-sidebar-base-gap-start);
  @include property(margin-right, $app-sidebar-base-gap-end);
  @include property(margin-top, $app-sidebar-base-gap-top);
  @include property(margin-bottom, $app-sidebar-base-gap-bottom);
}

// Vars
:root {
  --kt-app-sidebar-width: #{$app-sidebar-base-width};
  --kt-app-sidebar-width-actual: #{$app-sidebar-base-width};

  --kt-app-sidebar-gap-start: #{$app-sidebar-base-gap-start};
  --kt-app-sidebar-gap-end: #{$app-sidebar-base-gap-end};
  --kt-app-sidebar-gap-top: #{$app-sidebar-base-gap-top};
  --kt-app-sidebar-gap-bottom: #{$app-sidebar-base-gap-bottom};
}

[data-kt-app-sidebar-minimize="on"] {
  --kt-app-sidebar-width: #{$app-sidebar-minimize-width};

  --kt-app-sidebar-gap-start: #{$app-sidebar-minimize-gap-start};
  --kt-app-sidebar-gap-end: #{$app-sidebar-minimize-gap-end};
  --kt-app-sidebar-gap-top: #{$app-sidebar-minimize-gap-top};
  --kt-app-sidebar-gap-bottom: #{$app-sidebar-minimize-gap-bottom};
}

// States
.app-sidebar {
  position: fixed;
  @include property(z-index, $app-sidebar-fixed-z-index);
  @include property(top, $app-sidebar-fixed-top);
  @include property(bottom, $app-sidebar-fixed-bottom);
  @include property(left, $app-sidebar-fixed-left);
  overflow-y: auto !important;

  [data-kt-app-sidebar-minimize="on"] & {
    transition: $app-sidebar-base-transition;

    //background-color: var(--kt-app-sidebar-minimize-bg-color);
    box-shadow: var(--kt-app-sidebar-minimize-box-shadow);
    border-left: var(--kt-app-sidebar-minimize-border-start);
    border-right: var(--kt-app-sidebar-minimize-border-end);

    @include property(margin-left, $app-sidebar-minimize-gap-start);
    @include property(margin-right, $app-sidebar-minimize-gap-end);
    @include property(margin-top, $app-sidebar-minimize-gap-top);
    @include property(margin-bottom, $app-sidebar-minimize-gap-bottom);
  }
}

// Utilities
[data-kt-app-sidebar-minimize="on"] {
  .app-sidebar-minimize-d-none {
    display: none !important;
  }

  .app-sidebar-minimize-d-flex {
    display: flex !important;
  }
}

.app-sidebar-border:not(:last-child)::after {
  content: " ";
  width: 216px;
  height: 1px;
  background-color: #000000;
  opacity: 0.1;
  position: absolute;
  bottom: 0;
  left: 0;
}

.profile-card {
  padding: 15px 20px;
  background-color: $white;
  border-radius: 6px;
}

.profile-img {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile-title {
  font-family: $font-family-montserrat;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  width: 148px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.profile-subtitle {
  font-family: $font-family-montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  opacity: 0.5;
  width: 148px;
}

.profile-box {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 4px;
  font-family: $font-family-montserrat;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.2;
  color: $primary-light;
}

//
// Modal
//

.modal {
  --#{$prefix}modal-color: var(--kt-modal-content-color);
  --#{$prefix}modal-bg: var(--kt-modal-content-bg);
  --#{$prefix}modal-padding: var(--kt-modal-content-padding);
  --#{$prefix}modal-border-color: var(--kt-modal-content-border-color);
  --#{$prefix}modal-box-shadow: var(--kt-modal-content-box-shadow-xs);
  --#{$prefix}modal-header-border-color: var(--kt-modal-header-border-color);
  --#{$prefix}modal-footer-bg: var(--kt-modal-footer-bg);
  --#{$prefix}modal-footer-border-color: var(--kt-modal-footer-border-color);
  pointer-events: none;
}

// Mobile dialog
.modal-dialog {
  outline: none !important;
  height: 100%;
  max-height: calc(100% - 50px);

  &.modal-dialog-centered {
    justify-content: center;
  }

  &.modal-dialog-centered.modal-width {
    max-width: 960px;
  }
}

// Modal header
.modal-header {
  align-items: center;
  justify-content: start;
  margin-bottom: 15px;
  padding: 0px 0px;
  @include border-top-radius($modal-content-border-radius);
  border-bottom: $modal-header-border-width solid var(--kt-modal-header-border-color);

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.58;
  }

  .modal-title {
    color: var(--kt-gray-900);
    font-family: Inter;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.59;
    letter-spacing: -0.22px;
  }

  &>.modal-second-title {
    margin-bottom: 5px;
    color: var(--kt-gray-900);
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: -0.14px;
  }
}

// Modal content
.modal-content {
  width: auto;
  color: var(--kt-modal-color);
  background-color: var(--kt-modal-bg);
  padding: 2.14rem;
  border: $modal-content-border-width solid var(--kt-modal-border-color);
  @include box-shadow(var(--kt-modal-box-shadow));
  height: auto;
  max-height: 100%;

  @include media-breakpoint-down(md) {
    padding: 2.14rem 1.5em;
  }
}

// Modal body
.modal-body {
  margin-bottom: 20px;
  padding: 0px 0px;
  max-height: 100%;
  overflow-y: auto;
}

// Modal footer
.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  background-color: var(--kt-modal-footer-bg);
  border-top: $modal-footer-border-width solid var(--#{$prefix}modal-footer-border-color);
}

.modal-footer>* {
  margin: 0px;
}

// Modal background
.modal-backdrop {
  --#{$prefix}backdrop-bg: var(--kt-modal-backdrop-bg);
  --#{$prefix}backdrop-opacity: var(--kt-modal-backdrop-opacity);
  pointer-events: none;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  .modal-content {
    @include box-shadow(var(--kt-modal-box-shadow-sm-up));
  }
}

// Utilities
.modal-rounded {
  @if $enable-rounded {
    border-radius: $modal-content-border-radius !important;
  }
}

.popup-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.close {
  border: none;
  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 2000;
  width: 44px;
  height: 44px;
  padding-block: 14px;
  border-radius: 50%;
  background-color: var(--kt-white);
  color: $gray-700;
  transition: color 250ms ease-in-out, box-shadow 250ms ease-in-out;

  & svg {
    fill: inherit;
    vertical-align: baseline;
  }

  &:hover,
  &:focus {
    color: var(--kt-primary);
    @include box-shadow(var(--kt-modal-box-shadow));
  }

  @include media-breakpoint-down(md) {
    top: 5px;
    right: 5px;
  }
}

.modal-event-none {
  pointer-events: auto;
}

.btn.modal-btn {
  width: 48%;
}

.btn.modal-btn-position {
  border: none;
  position: absolute;
  right: 5px;
  bottom: 20px;
}

.modal-input {
  display: block;
  width: 100%;
  height: 54px;
  margin-bottom: 15px;
  padding: 15px;
  border: none;
  border-radius: 6px;
  background-color: $gray-100;
  color: $gray-600;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.64;

  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    background-color: $gray-100;
  }
}

.form-modal {
  max-width: 450px;
  width: 100%;
  padding: 20px 30px 30px 30px;
  border-radius: 12px;
  background-color: var(--kt-white);
}

.card-modal {
  width: 100%;
  padding: 10px 20px;
  background-color: var(--kt-white);
  border-radius: 12px;

  .card-modal_thumba {
    margin-bottom: 17px;
    background-color: transparent;

    & img {
      display: block;
      object-fit: contain;
      object-position: center center;
    }
  }
}

.modal-dialog-payment {
  max-width: 900px;

  .modal-content {
    height: 100%;
    padding: 0;

    .modal-body {
      border-radius: var(--bs-modal-border-radius);
      margin-bottom: 0px !important;
    }
  }
}
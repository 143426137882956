//
// Header
//

.app-header .app-container {
  background-color: var(--kt-white);
}

// General mode
.app-header-menu {
  .menu {
    .menu-item {
      @include menu-link-default-state(
        $title-color: var(--kt-gray-700),
        $icon-color: var(--kt-gray-500),
        $bullet-color: var(--kt-gray-500),
        $arrow-color: var(--kt-gray-500),
        $bg-color: null
      );

      @include menu-link-hover-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-menu-link-bg-color-active)
      );

      @include menu-link-show-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: null
      );

      @include menu-link-here-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-menu-link-bg-color-active)
      );

      @include menu-link-active-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-menu-link-bg-color-active)
      );
    }

    .tab-header {
      line-height: 23px;
    }

    .tab-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: var(--kt-gray-700); //--kt-primary
      color: var(--kt-info-light);
      margin-right: 16px;
    }
  }

  .menu-extended {
    --kt-menu-link-bg-color-active: rgba(var(--kt-gray-100-rgb), 0.7);
    --kt-menu-link-bg-color-hover: rgba(var(--kt-gray-100-rgb), 0.7);

    .menu-custom-icon {
      background-color: var(--kt-gray-100);
    }

    .menu-link {
      &.active,
      &:hover {
        .menu-custom-icon {
          background-color: var(--kt-gray-200);
        }
      }
    }
  }
}

// Desktop mode
.app-header-menu {
  .menu {
    // Menu root item
    > .menu-item {
      margin-right: 0.5rem;

      > .menu-link {
        padding-top: 0.775rem;
        padding-bottom: 0.775rem;
        font-weight: $font-weight-semibold;
      }

      @include menu-link-here-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-app-header-base-menu-link-bg-color-active)
      );
    }
  }
}

//
// Custom Header(used by Light Sidebar layout only)
//

.app-header-menu {
  .menu {
    // Menu root item
    > .menu-item {
      @include menu-link-here-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-app-sidebar-light-header-menu-link-bg-color-active),
        $all-links: false
      );

      @include menu-link-active-state(
        $title-color: var(--kt-primary),
        $icon-color: var(--kt-primary),
        $bullet-color: var(--kt-primary),
        $arrow-color: var(--kt-primary),
        $bg-color: var(--kt-app-sidebar-light-header-menu-link-bg-color-active),
        $all-links: false
      );
    }
  }
}

// Desktop mode
.app-header {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);
}

//
// Sidebar Minimize
//

.app-sidebar-logo-minimize {
  display: none;
}

// Sidebar footer
.app-sidebar-footer {
  .btn-custom {
    .btn-icon {
      display: none;
    }
  }
}

// Desktop sidebar minimize mode
@include app-layout-minimize(app-sidebar) {
  .app-sidebar-logo {
    .app-sidebar-logo-default {
      display: none;
    }

    .app-sidebar-logo-minimize {
      display: inline-block;
    }
    .app-sidebar-toggle {
      transform: translate(-15%, -50%) !important;
      background-color: var(--kt-primary) !important;
    }
  }

  .app-sidebar-wrapper {
    width: var(--kt-app-sidebar-width-actual);
  }

  .app-sidebar-wrapper.profile-card {
    width: 50px;
    background-color: transparent;
    padding: 15px 7px;
  }

  .app-sidebar-border:not(:last-child)::after {
    content: " ";
    width: 50px;
    height: 1px;
    @include app-layout-transition(width);
  }

  .app-sidebar-menu {
    .menu-content,
    .menu-title {
      min-width: 180px;
      opacity: 0;
      @include app-layout-transition(opacity);
    }

    .menu-item {
      .menu-link {
        width: 50px;
      }
      &.show > .menu-sub {
        height: 0;
        overflow: hidden;
        @include app-layout-transition(height);
      }
    }
  }

  .app-sidebar-footer {
    .btn-custom {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .btn-label {
        width: 0;
        display: none;
      }

      .btn-icon {
        width: auto;
        display: block;
      }
    }
  }
}

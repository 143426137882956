//
// Main
//

// General mode
.app-main {
  position: relative;

  width: 100%;
  height: calc(
    100vh - var(--kt-app-header-height) - var(--kt-app-footer-height)
  );
  min-width: 1160px;
  overflow-y: auto;

  &.no-menu {
    height: calc(
    100vh - var(--kt-app-footer-height)
  );

  &.no-footer {
    height: 100vh;
  }
  }

  &.no-footer {
    height: calc(
    100vh - var(--kt-app-header-height)
  );
  }
}

// Desktop mode
// Base
.app-main {
  transition: $app-main-transition;

  @include property(padding-left, $app-main-padding-x);
  @include property(padding-right, $app-main-padding-x);
  @include property(padding-top, $app-main-padding-y);
  @include property(padding-bottom, $app-main-padding-y);

  @include property(margin-left, $app-main-margin-x);
  @include property(margin-right, $app-main-margin-x);
  @include property(margin-top, $app-main-margin-y);
  @include property(margin-bottom, $app-main-margin-y);

  @include media-breakpoint-down(md) {
    padding: 16px 8px;
  }
}

// For mobile pages 

.app-main {
  &.width-auto {
    @include media-breakpoint-down(md) {
      min-width: initial;
    }
  }
}

// Integration
.app-main {
  // Sidebar
  [data-kt-app-sidebar-sticky="true"] & {
    margin-left: calc(
      var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) +
        var(--kt-app-sidebar-gap-end, 0px)
    );
  }

  // Sidebar Panel
  [data-kt-app-sidebar-panel-sticky="true"] & {
    margin-left: calc(
      var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) +
        var(--kt-app-sidebar-gap-end, 0px) + var(--kt-app-sidebar-panel-width) +
        var(--kt-app-sidebar-panel-gap-start, 0px) +
        var(--kt-app-sidebar-panel-gap-end, 0px)
    );
  }

  // Aside
  [data-kt-app-aside-sticky="true"] & {
    margin-right: calc(
      var(--kt-app-aside-width) + var(--kt-app-aside-gap-start, 0px) +
        var(--kt-app-aside-gap-end, 0px)
    );
  }
}

.main-wrapper {
  width: calc(100% - $app-main-padding-x - var(--kt-app-toolbar-base-width));
  height: calc(100% - var(--kt-app-footer-height) - 50px);
  text-align: center;
  overflow: overlay;

  &.no-footer {
    height: 100%;
    overflow: hidden;
  }
}

.referral_container {
  position: relative;
  width: 100%;
  padding: 30px 0px 52px 30px;
  border-radius: 12px;
  background: var(--kt-white);
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -5px;
    right: -100px;
    width: 514px;
    height: 474px;
    background-image: url("~/public/images/referral_2.svg");
    background-repeat: no-repeat;
    object-fit: contain;
    object-position: top left;
  }
}

.referral_box {
  position: relative;
  width: 654px;
  z-index: 10;
}

.container_title {
  margin-bottom: 30px;
  color: var(--kt-gray-900);
  font-weight: 700;
  font-size: 22px;
  line-height: 1.59;
  letter-spacing: -0.22px;
}

.referral_box-form {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #eef0f8;
}

.referral_bonuses {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
  padding: 10px 30px 20px 30px;
  border-radius: 6px;
  background-color: #eef0f8;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -9px;
    right: -23px;
    width: 168px;
    height: 130px;
    background-image: url("~/public/images/referral_1.svg");
    background-repeat: no-repeat;
    object-fit: contain;
    object-position: center center;
  }
}

.referral_bonuses-item {
  list-style: none;
  margin-right: 47px;
}

.referral_bonuses-number {
  margin-bottom: 0px;
  color: var(--kt-primary);
  font-weight: 700;
  font-size: 34px;
  line-height: 1.56;
  letter-spacing: -0.68px;
}

.referral_bonuses-text {
  margin-bottom: 0px;
  color: var(--kt-black);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.625;
}

.balance_container {
  padding: 20px 30px 30px 30px;
  border-radius: 12px;
  background-color: var(--kt-white);
}

.balance_box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.balance_second-title {
  color: var(--kt-primary);
  font-weight: 700;
  font-size: 22px;
  line-height: 1.59;
  letter-spacing: -0.22px;
  white-space: nowrap;
}

.balance_label {
  position: relative;

  & span {
    position: absolute;
    top: 15px;
    right: 25px;
    margin-left: 10px;
    color: var(--kt-gray-900);
    font-weight: 500;
    font-size: 14px;
    line-height: 1.64;
  }
}

.balance_input {
  width: 225px;
  margin-right: 10px;
  color: var(--kt-gray-900);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.64;
}

.balance_form {
  display: grid;
  grid-template-columns: repeat(2, minmax(138px, 1fr)) 155px repeat(
      3,
      minmax(138px, 1fr)
    );
  justify-content: space-between;
  align-items: end;
  grid-gap: 15px;
  max-width: 100%;
  padding: 20px;
  border-radius: 6px;
  background-color: var(--kt-gray-100);
}

.balance_form-label {
  position: relative;

  & span {
    display: block;
    margin-bottom: 10px;
    color: #7c7c7c;
    font-weight: 400;
    font-size: 14px;
  }

  & .text-error {
    position: absolute;
    right: 0px;
    top: 2px;
    display: inline-block;
    margin-bottom: 10px;
    color: $input-warning;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
  }

  & .balance_text-label {
    position: absolute;
    margin-bottom: 0px;
    bottom: 14px;
    right: 15px;
    color: var(--kt-gray-900);
    font-weight: 500;
    font-size: 14px;
    //text-decoration: line-through;
  }
}

.rate-item {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  border-bottom: 1px solid $body-bg;
}

.rate-item_title,
.rate-item_text {
  margin-bottom: 0px;
  color: var(--kt-gray-900);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.62;
}

.rate-item_text {
  font-weight: 600;
}

.settings_container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.settings_box {
  width: 52%;
  background-color: transparent;
}

.rating_box {
  width: 32%;
  margin-right: 40px;
  padding: 25px 30px 139px 30px;
  background-color: var(--kt-white);
  border-radius: 12px;
  background-image: url("~/public/images/diagram.svg");
  background-size: 380px 109px;
  background-position: center bottom;
  background-repeat: no-repeat;

  & .rating_table-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    
    & .rating_table-item_title {
      margin-bottom: 0px;
      color: var(--kt-gray-900);
      font-weight: 600;
      font-size: 18px;
      line-height: 1.78; 
      letter-spacing: -0.18px;
    }

    & .rating_table-item_text {
      margin-bottom: 0px;
      color: var(--kt-gray-400);
      font-weight: 500;
      font-size: 12px;
      line-height: 1.5;
    }

    & .rating_table-item_box {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 43px;
      padding: 10px 9px;
      border-radius: 4px;

      &.box-up {
        background-color: var(--kt-success-light);

        & > span {
          color: $explore-success;
        }
      }

      &.box-down {
        background-color: $warning-light;

        & > span {
          color: $danger-active;
        }
      }

      & > span {
        margin-left: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
      }
    }
  }
}

.rating_box-second {
  width: 64%;
  padding: 25px 30px 30px 30px;
  background-color: var(--kt-primary);
  border-radius: 12px;

  & > .rating_title {
    margin-bottom: 17px;
    color: var(--kt-white);
    font-weight: 700;
    font-size: 34px;
    line-height: 1.56;
    letter-spacing: -0.68px;
  }

  & .rating_list {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0px;

    & .rating_list-item {
      list-style: none;
      padding: 15px 20px 20px 20px;
      border-radius: 6px;
      background-color: $bg-orang;
      background-size: 142px 138px;
      background-position: 108% 100%;
      background-repeat: no-repeat;
      overflow: hidden;

      &:nth-child(1) {
        background-image: url("~/public/images/rating_1.svg");
      }

      &:nth-child(2) {
        background-image: url("~/public/images/rating_2.svg");
      }

      &:nth-child(3) {
        background-image: url("~/public/images/rating_3.svg");
      }

      &:nth-child(4) {
        background-image: url("~/public/images/rating_4.svg");
      }

      &.active {
        background-color: rgba(245, 248, 250, 0.10);
      }

      & .rating_list-item_number {
        margin-bottom: 0px;
        color: var(--kt-white);
        font-weight: 700;
        font-size: 24px;
        line-height: 1.33; 
        letter-spacing: -0.24px;
      }

      & .rating_list-item_text {
        margin-bottom: 10px;
        color: var(--kt-white);
        font-weight: 500;
        font-size: 14px;
        line-height: 1.64; 
      }
    }
  }
}

.settings_thumba,
.support_thumba {
  width: 48%;
  aspect-ratio: 598 / 586;

  & img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.support_thumba {
  margin-left: 78px;
}

.courses_container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 25px 20px;
  border-radius: 12px;
  background-color: var(--kt-white);
}

.review_box {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // justify-content: space-between;
  // align-items: start;
  // grid-gap: 30px;
  // max-width: 100%;
  // padding-left: 0px;
}

.review_box-item {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}